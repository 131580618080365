/* line 6, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
.jumbo-image {
  background-color: #f4f4f4; }
  @media (max-width: 767.98px) {
    /* line 6, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
    .jumbo-image {
      background-image: none !important; } }
  @media (min-width: 768px) {
    /* line 6, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
    .jumbo-image {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; } }
  @media (min-width: 992px) {
    /* line 20, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
    .jumbo-image .container {
      min-height: 20rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; } }
  @media (min-width: 768px) {
    /* line 28, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
    .jumbo-image .jumbo-image-content {
      max-width: 55%; } }
  /* line 33, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
  .jumbo-image .jumbo-image-content__company {
    color: #1f477a; }
    /* line 36, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
    .jumbo-image .jumbo-image-content__company small {
      font-weight: bold;
      text-transform: uppercase;
      vertical-align: super; }
    /* line 42, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
    .jumbo-image .jumbo-image-content__company::before {
      font-family: "Fontello";
      font-size: 2.25rem;
      line-height: 2.25rem;
      content: "\E805"; }
  /* line 50, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
  .jumbo-image .jumbo-image-content h1 {
    font-family: "Ubuntu Light";
    font-size: 1.5rem;
    line-height: 2rem;
    color: #2e2e2e; }
    @media (min-width: 768px) {
      /* line 50, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
      .jumbo-image .jumbo-image-content h1 {
        font-size: 2rem;
        line-height: 2.5rem; } }
    @media (min-width: 992px) {
      /* line 50, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
      .jumbo-image .jumbo-image-content h1 {
        font-size: 2.5rem;
        line-height: 3.5rem; } }
    @media (min-width: 1200px) {
      /* line 50, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbo-image.scss */
      .jumbo-image .jumbo-image-content h1 {
        font-size: 2.8rem;
        line-height: 3.75rem; } }

/* line 7, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
.card-deck-service .card {
  margin-bottom: 3rem; }
  @media (min-width: 768px) {
    /* line 7, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
    .card-deck-service .card {
      margin-bottom: 4rem; } }
  /* line 14, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck-service .card .card-title {
    max-width: 80%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: .7rem 1.5rem;
    background-color: rgba(43, 43, 43, 0.9);
    font-family: "Ubuntu Light";
    font-size: 1.5rem;
    color: white; }
    @media (min-width: 768px) {
      /* line 14, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
      .card-deck-service .card .card-title {
        padding: .7rem 2rem;
        font-size: 1.7rem; } }
    @media (min-width: 992px) {
      /* line 14, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
      .card-deck-service .card .card-title {
        padding: .7rem 2.375rem;
        font-size: 1.875rem; } }
  /* line 37, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck-service .card .card-title-anchor:hover .card-title {
    background-color: #11995e; }
  /* line 41, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck-service .card .card-text {
    font-size: 1rem;
    color: #202020; }
  /* line 46, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck-service .card .card-footer {
    color: #20477b; }
    /* line 49, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
    .card-deck-service .card .card-footer a.link-border-arrow {
      padding-bottom: .5rem;
      font-size: 1.25rem !important; }
      /* line 53, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
      .card-deck-service .card .card-footer a.link-border-arrow::after {
        bottom: 0.5rem; }
        @media (min-width: 768px) {
          /* line 53, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
          .card-deck-service .card .card-footer a.link-border-arrow::after {
            bottom: 0; } }

/* line 66, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
.card-deck-news .card {
  text-decoration: none; }
  /* line 69, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck-news .card::before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #11995e;
    content: '';
    -webkit-transition: height .2s;
    transition: height .2s; }
  /* line 81, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck-news .card .card-title {
    color: #616161; }
  /* line 85, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck-news .card:hover:before {
    height: 3px; }
  /* line 89, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck-news .card:hover h2 {
    text-decoration: underline; }

@media (min-width: 0) {
  /* line 100, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck .card {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
    -ms-flex-preferred-size: calc(100% - 30px);
    flex-basis: calc(100% - 30px); } }

@media (min-width: 576px) {
  /* line 100, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck .card {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 30px);
    flex: 0 0 calc(50% - 30px);
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px); } }

@media (min-width: 768px) {
  /* line 100, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck .card {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 30px);
    flex: 0 0 calc(50% - 30px);
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px); } }

@media (min-width: 992px) {
  /* line 100, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck .card {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.3333333333% - 30px);
    flex: 0 0 calc(33.3333333333% - 30px);
    -ms-flex-preferred-size: calc(33.3333333333% - 30px);
    flex-basis: calc(33.3333333333% - 30px); } }

@media (min-width: 1200px) {
  /* line 100, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck .card {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.3333333333% - 30px);
    flex: 0 0 calc(33.3333333333% - 30px);
    -ms-flex-preferred-size: calc(33.3333333333% - 30px);
    flex-basis: calc(33.3333333333% - 30px); } }

/* line 108, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
.card-deck.card-deck-results .card {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 30px);
  flex: 0 0 calc(100% - 30px);
  -ms-flex-preferred-size: calc(100% - 30px);
  flex-basis: calc(100% - 30px); }

/* to fix 100% width on IE11 */
@media (max-width: 575.98px) {
  /* line 115, src/Pandect.Web/Pandect.Web/Content/sass/components/_card.scss */
  .card-deck .card {
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important; } }

/* line 2, src/Pandect.Web/Pandect.Web/Content/sass/components/_pagination.scss */
.pagination li.page-item {
  padding: 0 .25rem; }

/* line 6, src/Pandect.Web/Pandect.Web/Content/sass/components/_pagination.scss */
.pagination a {
  font-size: 1.25rem; }
  /* line 9, src/Pandect.Web/Pandect.Web/Content/sass/components/_pagination.scss */
  .pagination a:hover {
    text-decoration: underline; }
  /* line 13, src/Pandect.Web/Pandect.Web/Content/sass/components/_pagination.scss */
  .pagination a.link-border-arrow {
    line-height: initial; }
    /* line 17, src/Pandect.Web/Pandect.Web/Content/sass/components/_pagination.scss */
    .pagination a.link-border-arrow--left::before {
      left: 0; }
    /* line 21, src/Pandect.Web/Pandect.Web/Content/sass/components/_pagination.scss */
    .pagination a.link-border-arrow--left::after {
      left: 0;
      right: auto;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1); }
    /* line 28, src/Pandect.Web/Pandect.Web/Content/sass/components/_pagination.scss */
    .pagination a.link-border-arrow:hover {
      text-decoration: none; }
    /* line 32, src/Pandect.Web/Pandect.Web/Content/sass/components/_pagination.scss */
    .pagination a.link-border-arrow::after {
      bottom: 0.5rem; }

/* line 38, src/Pandect.Web/Pandect.Web/Content/sass/components/_pagination.scss */
.pagination .disabled,
.pagination .disabled a {
  text-decoration: none; }

@media (min-width: 768px) {
  /* line 1, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
  .contact-panel {
    background-image: url("/Content/img/contact-background.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    /* line 8, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
    .contact-panel h2 a {
      font-size: 2.5rem;
      line-height: 3rem; } }

/* line 14, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
.contact-panel p {
  font-family: "Ubuntu Light";
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 768px) {
    /* line 14, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
    .contact-panel p {
      font-size: 1.5rem;
      line-height: 2rem; } }

/* line 25, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
.contact-panel .contact-panel-icon::before {
  font-family: "Fontello";
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: white;
  content: "\E804"; }
  @media (min-width: 768px) {
    /* line 25, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
    .contact-panel .contact-panel-icon::before {
      font-size: 4.5rem;
      line-height: 4.5rem; } }

@media (min-width: 1200px) {
  /* line 39, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
  .contact-panel .contact-panel-number {
    font-size: 2rem; } }

/* line 44, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
.contact-panel a.link-border-arrow::after {
  bottom: 0.5rem; }
  @media (min-width: 768px) {
    /* line 44, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
    .contact-panel a.link-border-arrow::after {
      bottom: -1px; } }

@media (min-width: 768px) {
  /* line 6, src/Pandect.Web/Pandect.Web/Content/sass/page/content.scss */
  .background-split {
    position: relative; }
    /* line 10, src/Pandect.Web/Pandect.Web/Content/sass/page/content.scss */
    .background-split::before {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #1f477a;
      content: ''; }
    /* line 20, src/Pandect.Web/Pandect.Web/Content/sass/page/content.scss */
    .background-split::after {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: #d24004;
      content: ''; }
    /* line 30, src/Pandect.Web/Pandect.Web/Content/sass/page/content.scss */
    .background-split .contact-panel {
      background-image: none;
      z-index: 1; }
      /* line 34, src/Pandect.Web/Pandect.Web/Content/sass/page/content.scss */
      .background-split .contact-panel .text-intro {
        font-size: 1.875rem;
        line-height: 2.375rem; } }

/* line 41, src/Pandect.Web/Pandect.Web/Content/sass/page/content.scss */
.background-split .about-panel {
  position: relative;
  overflow: hidden; }
  /* line 45, src/Pandect.Web/Pandect.Web/Content/sass/page/content.scss */
  .background-split .about-panel .about-icon {
    position: absolute;
    bottom: 0;
    right: -2rem;
    -webkit-transform: translateY(65%);
    transform: translateY(65%);
    font-family: Fontello;
    font-size: 18rem;
    line-height: 18rem;
    color: #fff;
    z-index: 1; }
    @media (min-width: 768px) {
      /* line 45, src/Pandect.Web/Pandect.Web/Content/sass/page/content.scss */
      .background-split .about-panel .about-icon {
        right: 0; } }
